import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import { ExecuteLayoutEventMethods } from "../../../General/commonFunctions";
import { ShowSnackBar } from "../../../General/globalFunctions";
 
class FactsFileButton extends Component {
    constructor(props) {
        super(props);
        this.inputRef = React.createRef();
    }

    ripOffControlSpecificAttributes() {
        const excluded = ["contentfield", "namefield", "sizefield", "typefield", "fileprops", "datatype"];
        return (
            Object.keys(this.props)
                .filter((t) => !excluded.includes(t))
                .reduce((obj, key) => {
                    obj[key] = this.props[key];
                    return obj;
                }, {}));
    }

    async handleButtonClick() {
        if (this.inputRef.current) {
            this.inputRef.current.click();
        }
    }

    async handleOnChange(e) {
        if (this.inputRef.current) {
            const file = this.inputRef.current.files[0];
            // const fileBase64 = await this.fileToBase64(file).catch(e => Error(e));
            const fileBase64 = this.props.datatype === "base64" ?
                await this.fileToBase64(file)
                : this.filetoBlob(file);
            if (fileBase64 instanceof Error) {
                ShowSnackBar("error", "Invalid File!");
                return;
            }
            let dataToMerge = {};
            if (this.props.dset) {
              
                if (this.props.namefield) dataToMerge = { [this.props.namefield]: file?.name };
                if (this.props.sizefield) dataToMerge = { ...dataToMerge, [this.props.sizefield]: file?.size };
                if (this.props.typefield) dataToMerge = { ...dataToMerge, [this.props.typefield]: file?.type };
                if (this.props.contentfield) dataToMerge = { ...dataToMerge, [this.props.contentfield]: fileBase64 };

                await ExecuteLayoutEventMethods([
                    {
                        exec: "mergedataset",
                        args: {
                            dset: this.props.dset,
                            data: dataToMerge
                        }
                    }
                ]);
            }
            await ExecuteLayoutEventMethods(this.props.whenchange, dataToMerge);
            this.inputRef.current.value = null;

        }
    }

    filetoBlob = (file) => {
        return window.URL.createObjectURL(file);
        // return await file.text();
    }

    async fileToBase64(file) {
        return new Promise((resolve, reject) => {
            let appFileReader = new FileReader();

            appFileReader.readAsDataURL(file);
            appFileReader.onload = () => resolve(appFileReader.result);
            appFileReader.onerror = error => reject(error);
        });
    }


    render() {
        let newProps = this.ripOffControlSpecificAttributes();

        return (
            <>
                <Button {...newProps} onClick={this.handleButtonClick.bind(this)}>{this.props.children}</Button>
                <input type="file" ref={this.inputRef} style={{ display: "none" }} {...this.props.fileprops}
                    onChange={this.handleOnChange.bind(this)}

                    onError={() => { alert("The Image is invalid!"); }}
                />
            </>
        );
    }
}

export default FactsFileButton; 