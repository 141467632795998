import { Grid, Dialog } from "@material-ui/core";
import React, { Component } from "react";
import GenericPageComponent from "./Pages/genericPageComponent";
import AppBarInnerComponent from "./Parts/appBarInnerComponent";
import PageHeaderSection from './Parts/pageHeaderSection';

class PopupComponent extends Component{

    render(){
        return(
            this.props.popupInfo ?
                <div className="popup-wrap">
                    {Object.keys(this.props.popupInfo).map((popupName, index) => {
                        let popup = this.props.popupInfo && this.props.popupInfo[popupName];
                        if (popup) {
                            return (
                                <Dialog
                                   
                                    PaperProps= { {className : "animate__animated animate__zoomIn"} }
                                    key={index} fullScreen={popup?.fullscreen ?? true} open={true} >
                                    {popup?.appbar ?
                                        (<AppBarInnerComponent {...popup?.appbar} popupName = {popupName} />)
                                        :
                                        (<></>)
                                    }
                                    <PageHeaderSection pageHeader={popup?.pageheader} />

                                    <Grid  
                                        container className="page-container"  style={popup?.style}>
                                        <GenericPageComponent pageInfo={popup}  />

                                    </Grid>
                                </Dialog>);
                        }
                        else return null;
                    })}
                </div>
                : <></>
        );

         
    }
}

export default PopupComponent; 