import React, { Component } from "react";
import { MenuItem, Popover } from "@material-ui/core";
import { HideAppMenu } from "../../../General/globalFunctions";
import { ExecuteLayoutEventMethods } from "../../../General/commonFunctions";
import PureJSComponentFactory from "../../Pages/Factory/pureJSComponentFactory";


class AppMenu extends Component {

    onMenuClose() {
        HideAppMenu();
        if (this.anchorElement) this.anchorElement.classList.remove("am-parent"); 

    }

    async onMenuClick(whenclick) {
        let clickEvent = whenclick;
        if (typeof whenclick == "string")
            clickEvent = JSON.parse(whenclick);
        await ExecuteLayoutEventMethods(clickEvent);
        this.onMenuClose();
    }

    render() {
        if (this.props.menuInfo?.items?.length) {
            this.anchorElement = document.getElementById(this.props.menuInfo?.anchor);
            if (this.anchorElement) this.anchorElement.classList.add("am-parent"); 
        }
        let menuTitle = new PureJSComponentFactory().scaffoldComponent(this.props.menuInfo?.title, this.props.menuInfo?.refdata);
        return (
            this.props.menuInfo?.items?.length > 0 && this.anchorElement ?
                <Popover
                    anchorEl={this.anchorElement}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    style={{ marginTop: "5px" }}
                    keepMounted
                    open={this.props.menuInfo?.open}
                    onClose={this.onMenuClose.bind(this)}
                >
                    {menuTitle}
                    {this.props.menuInfo?.items?.map((t, i) => {

                        if (this.props.menuInfo?.layout) {

                            let menuContent = new PureJSComponentFactory().scaffoldComponent(this.props.menuInfo?.layout, t);
                            return (
                                <MenuItem key={i} onClick={() => this.onMenuClick(t.whenclick)}>{menuContent}</MenuItem>
                            );
                        }
                        else {

                            return (
                                <MenuItem key={i} onClick={() => this.onMenuClick(t.whenclick)}>{t.text}</MenuItem>
                            );
                        }
                    })}
                </Popover>
                : <></>
        );
    }
}

export default AppMenu;